import screenshot from '../assets/birbli-delete-account-ss.jpg';
import './DeleteAccount.scss';
import { Link } from 'react-router-dom';

export const DeleteAccount = () => {
    return (
        <div className='container bg-transparent'>
            <h1>How to Delete Your Birbli Account</h1>
            <ol>
                <li>Log into your Birbli account using the Birbli app.</li>
                <li>Go to the profile page and scroll down. You will see a button called <strong>Delete
                    Account</strong>.
                </li>
                <div style={{textAlign: 'center', margin: '20px 0'}}>
                    <img
                        src={screenshot}
                        alt='Delete Account Section'
                        style={{maxWidth: '30%', height: 'auto', border: '1px solid #ccc'}}
                    />
                </div>
                <li>Click <strong>Delete Account</strong> and follow the on-screen prompts.</li>
                <li>You will receive an email to verify that you want to delete your account.</li>
                <li>User data will be retained for a short period of time after the account is deleted in accordance
                    with our <Link to={ { pathname: '/privacy' } } className='privacy-link'>privacy policy</Link>.
                </li>
            </ol>
        </div>
    );
}
