import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import './ResetPassword.scss';

const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($id: String!, $newPassword: String!, $token: String!) {
    resetPassword(userID: $id, newPassword: $newPassword, token: $token)
  }
`;

export const PasswordReset = () => {
    const { id, token } = useParams<{ id: string; token: string }>();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [resetPassword, { loading, error }] = useMutation(RESET_PASSWORD_MUTATION);
    const [isResetSuccessful, setIsResetSuccessful] = useState(false);


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (newPassword === confirmPassword && newPassword.length) {
            try {
                resetPassword({
                    variables: { id, newPassword, token },
                }).then(() => {
                    setIsResetSuccessful(true);
                }).catch((e) => {
                    console.log(e);
                });
            } catch (e) {
                console.log('Error resetting password:', e);
            }
        }
    };

    return (
        <div className='container'>
            {isResetSuccessful ? (
                <div className='fs-1 text-center'>Password has been successfully reset</div>
            ) : (
                <form onSubmit={handleSubmit} className='form-group p-5' id='resetPasswordForm'>
                    <h2 className='fs-3 text-center pb-3'>Reset password</h2>
                    <input
                        type='password'
                        className='form-control'
                        placeholder='New Password'
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                    <input
                        type='password'
                        className='form-control'
                        placeholder='Confirm New Password'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                    <label className='checkbox d-block text-center'>
                        <input type='checkbox' value='log-out' id='log-out' name='log-out' /> Log out all devices
                    </label>
                    <div className='justify-content-center d-flex'>
                        {loading ? (
                            <span className='loader' />
                        ): (
                            <button className='btn btn-lg btn-primary btn-block' type='submit' style={{ backgroundColor: '#2D9797FF' }} disabled={newPassword !== confirmPassword || !newPassword}>Submit</button>
                        )}
                    </div>
                </form>
            )}
            {error && !isResetSuccessful && <p className='text-center mt-3'><b>Error resetting password. Please try again.</b></p>}
        </div>
    );
};
