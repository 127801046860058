import './Home.scss';
import birb from '../assets/birb.png';
import encryptedImage from '../assets/encrypted-photo.png';
import cardinal from '../assets/cardinal.png';
import bookingMock from '../assets/booking_mock.png';
import homeMock from '../assets/home_mock.png';
import { Link } from 'react-router-dom';

export const Home = () => {
    return (
        <>
            <div className='container bg-transparent'>
                <div className='hero'>
                    <div className='d-flex text-container align-items-center'>
                        <div className='text-container'>
                            <h2 className='jumbo-text fw-bold'>
                                Text Securely.<br/>
                                Earn Effortlessly.<br/>
                                Connect Meaningfully.<br/><br/>
                                <Link to='/' className='btn btn-lg fs-2' style={{backgroundColor: '#C3EEEDFF'}}>Download Birbli</Link>
                            </h2>
                        </div>
                    </div>
                    <div className='image-container'>
                        <img src={birb} className='birb d-none d-md-block' alt='birb'/>
                        <div className='mock-images'>
                            <img src={bookingMock} className='mock' alt='booking mock'/>
                            <img src={homeMock} className='mock' alt='home mock'/>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: 'rgb(211, 222, 255)', marginBottom: '-20px' }}>
                <div className='container'>
                    <h1 className='text-center pt-5 fw-bold'>Why Birbli?</h1>
                    <div className='d-flex mt-5 pb-5 row'>
                        <div className='col-md-4 order-1 p-3' style={{ animation: '1s ease-out 0s 1 slideInFromLeft' }}>
                            <h2 className='fw-bold'>Send Secure Messages</h2>
                            <p className='fs-4'>Every message on Birbli is end-to-end encrypted, so only you and the recipient can read it. Privacy is core to what we do—your conversations stay yours.</p>
                        </div>
                        <div className='col-md-6 order-md-1 bg-white offset-md-2' style={{ borderRadius: '30px' }}>
                            <div className='container p-4'>
                                <div className='row position-relative'>
                                    <img src={encryptedImage} alt='encrypted-cardinal' style={{ borderRadius: '45px', backgroundColor: '#725e93', width: '75%', zIndex: 3, top: 0, left: 0, position: 'absolute', animation: '5s ease-out 2s 1 fadeOut forwards' }} className='p-1'/>
                                    <img src={cardinal} alt='cardinal' style={{ borderRadius: '45px', backgroundColor: '#725e93', width: '75%', zIndex: 1, top: 0, left: 0, position: 'relative' }} className='p-1'/>
                                </div>
                                <div className='row p-4' style={{ float: 'right', width: '200px' }}>
                                    <p className='fs-4 p-2 text-center' style={{ borderRadius: '90px', backgroundColor: '#C3EEEDFF' }}>Cool photo!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
