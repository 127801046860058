import { Home } from  './home/Home'
import { VerifyEmail } from './verifyEmail/VerifyEmail';
import { EmailVerified } from './emailVerified/EmailVerified';
import { Route, Routes, useLocation } from 'react-router-dom';
import { PasswordReset } from './resetPassword/ResetPassword';
import { DeleteUser } from './deleteUser/DeleteUser';
import { UserDeleted } from './userDeleted/UserDeleted';
import { Navbar } from './shared/Navbar';
import { Footer } from './shared/Footer';
import { Privacy } from './privacy/Privacy';
import { TermsAndConditions } from './terms/TermsAndConditions';
import { DeleteAccount } from './deleteAccount/DeleteAccount';
import 'bootstrap/dist/css/bootstrap.css';
import './shared/styles.scss';
import { useEffect } from 'react';
import GA from 'react-ga4';
import './App.css';

export const App = () => {

    const location = useLocation();

    useEffect(() => {
        GA.send({
            hitType: 'pageview',
            page: location.pathname + location.search,
        })
    }, [location])

    return (
        <div className='container-flex'>
            <Navbar />
            <div className='global-container'>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='verifyEmail/:id/:token' element={<VerifyEmail />} />
                    <Route path='/emailVerified' element={<EmailVerified />} />
                    <Route path='/userDeleted' element={<UserDeleted />} />
                    <Route path='/resetPassword/:id/:token' element={<PasswordReset />} />
                    <Route path='/deleteUser/:id/:token' element={<DeleteUser />} />
                    <Route path='/privacy' element={<Privacy />} />
                    <Route path='/tc' element={<TermsAndConditions />} />
                    <Route path='/deleteAccount' element={<DeleteAccount />} />
                </Routes>
            </div>
            <Footer />
        </div>
    );
}
