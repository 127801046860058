import { Link } from 'react-router-dom';
import logo from '../assets/birbli_logo.png';
import './Navbar.scss';
import { useEffect, useState } from 'react';

export const Navbar = () => {

    const offsetPosition = 20;

    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const onScroll = () => setOffset(window.scrollY);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);
    
    return (
        <nav className='navbar fixed-top bg-white' style={offset > offsetPosition ? {borderBottom: '1px solid #c6c6c6'} : {borderBottom: 'none'}}>
            <div className='container bg-transparent'>
                <Link to='/' className='navbar-brand'>
                    <img src={logo} alt='birbli logo' width={60} height={60} />
                    <span className='company-name'><b>BIRBLI</b></span>
                </Link>
            </div>
        </nav>
    );
};
