import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';

enum DeleteUserStatus {
    Pending = 'pending',
    Success = 'success',
    Failed = 'failed',
}

const DELETE_USER_MUTATION = gql`
  mutation deleteUser($userID: String!, $token: String!) {
    deleteUser(userID: $userID, token: $token)
  }
`;

export const DeleteUser = () => {
    const [status, setStatus] = useState<DeleteUserStatus>(DeleteUserStatus.Pending);
    const navigate = useNavigate();
    const { id, token } = useParams<{ id: string; token: string }>();
    const [deleteUser] = useMutation(DELETE_USER_MUTATION);

    // Try to verify email when landing on this page
    useEffect(() => {
        deleteUser({ variables: { userID: id, token: token } }).then(() => {
            navigate('/userDeleted');
        }).catch((e) => {
            setStatus(DeleteUserStatus.Failed);
            console.log('Account deletion failed: ', e);
        });
    }, []);

    return (
        <div className='container text-center fs-1'>
            {status === DeleteUserStatus.Pending && (
                <>
                    <h1>Removing account</h1>
                    <div className='justify-content-center d-flex'>
                        <span className='loader' />
                    </div>
                </>
            )}
            {status === DeleteUserStatus.Failed && (
                <>
                    <h1>Account removal failed</h1>
                    <div className='text-danger'>X</div>
                </>
            )}
        </div>
    );
};
