export const EmailVerified = () => {
    return (
        <div className='container fs-1 text-center'>
            <h1 className='mt-5'>Email Verified</h1>
            <span className='text-success'>
                ✓ {/* You might want to replace this with an icon for better visuals */}
            </span>
        </div>
    );
};
